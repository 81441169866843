import React, { useState } from "react";
import axios from "axios";

// Define the structure for each message
interface IMessage {
  role: "user" | "assistant" | "system";
  content: string;
}

function Chat() {
  const [message, setMessage] = useState<string>("");
  const [skill, setSkill] = useState<string>("");
  const [responses, setResponses] = useState<IMessage[]>([
    { role: "system", content: "Siz yordam beruvchi chatbot hisoblanasiz." },
  ]);

  const sendMessage = async () => {
    if (!message.trim() && !skill.trim()) return;

    const autoQuestion = skill
      ? `Give me multiple choice 20 questions (from easy to hard) on json format which is ${skill}. Json format example: {question:""; a:""; b:""; c:""; d:""; answers: d}.`
      : message;

    // Add the user's message to the chat
    setResponses((currentResponses) => [
      ...currentResponses,
      { role: "user", content: autoQuestion },
    ]);

    try {
      const response = await axios.post("https://ai.urconnection.co.kr/", {
        messages: [
          {
            role: "system",
            content: "Siz yordam beruvchi chatbot hisoblanasiz.",
          },
          ...responses, // Include previous messages for context
          { role: "user", content: autoQuestion },
        ],
      });

      console.log("API Response:", response.data);

      // Extract the assistant's response
      const assistantMessage =
        response.data?.content || "No response from assistant.";

      // Append the assistant's response to the chat
      setResponses((currentResponses) => [
        ...currentResponses,
        { role: "assistant", content: assistantMessage },
      ]);
    } catch (error) {
      console.error("Error sending message: ", error);
      setResponses((currentResponses) => [
        ...currentResponses,
        {
          role: "assistant",
          content: "Xatolik yuz berdi. Qayta urinib ko'ring.",
        },
      ]);
    } finally {
      setMessage(""); // Clear the input field
    }
  };

  return (
    <div>
      {/* Input for skill */}
      <input
        type="text"
        value={skill}
        onChange={(e) => setSkill(e.target.value)}
        placeholder="Enter skill (e.g., JavaScript, Python, etc.)"
        style={{ marginBottom: "10px" }}
      />

      <button onClick={sendMessage}>Yuborish</button>

      {/* Display chat messages */}
      <div>
        {responses.map((msg, index) => (
          <p key={index} style={{ margin: "5px 0" }}>
            <strong>{msg.role === "user" ? "Siz" : "Chatbot"}:</strong>{" "}
            {msg.content}
          </p>
        ))}
      </div>
    </div>
  );
}

export default Chat;
