import { Apartment, Person } from "@mui/icons-material";
import {
  Box,
  FormLabel,
  Input,
  List,
  ListItem,
  ListItemDecorator,
  RadioGroup,
  Typography,
} from "@mui/joy";
import Radio from "@mui/joy/Radio";
import { FormControl } from "@mui/material";
import React, { useState } from "react";
import Select, { selectClasses } from "@mui/joy/Select";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import Option from "@mui/joy/Option";
import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";
import ReasonComponent from "./reason";
import axios from "axios";
import { toast } from "react-toastify";
import { baseURL } from "../../../../../config";

interface FormValues {
  name: string;
  phone: string;
  country: string;
  position: string;
  place_of_study: string;
  workplace: string;
  gender: string;
  birthday: string;
  visa: string;
  address: string;
}
const NewUser = ({ setModal }: any) => {
  const [selectedOption, setSelectedOption] = React.useState("");
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    phone: "",
    country: "",
    position: "",
    place_of_study: "",
    workplace: "",
    gender: "",
    birthday: "",
    visa: "",
    address: "",
  });
  const [reason, setReason] = React.useState({});
  const [active, setActive] = useState<boolean>(false);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setSelectedOption(value);
    if (value === "Other") {
      setFormValues({
        ...formValues,
        workplace: "",
        place_of_study: "",
        position: "Other",
      });
    }
    if (value === "Employee") {
      setFormValues({
        ...formValues,
        place_of_study: "",
        position: "Employee",
      });
    }
    if (value === "Student") {
      setFormValues({
        ...formValues,
        workplace: "",
        position: "Student",
      });
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleSelectChange = (e: any) => {
    setFormValues({ ...formValues, visa: e?.target?.innerText });
  };

  const getReasonData = (data: any) => {
    setReason(data);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(baseURL + "/user-data/sign-up", {
        is_admin: true,
        name: formValues.name,
        phone: formValues.phone,
        position: formValues.position,
        place_of_study: formValues.place_of_study,
        workplace: formValues.workplace,
        gender: formValues.gender,
        birthday: formValues.birthday,
        visa: formValues.visa,
        address: formValues.address,
        country: formValues.country,
        reason: JSON.stringify(reason),
        language: "English",
      });
      if (response.data.success) {
        toast.success("Successfully send!");
        setActive(true);
        setFormValues({
          name: "",
          phone: "",
          country: "",
          position: "",
          place_of_study: "",
          workplace: "",
          gender: "",
          birthday: "",
          visa: "",
          address: "",
        });
        setSelectedOption("");
        setModal(false);
        window.location.reload();
      }
    } catch (error: any) {
      if (!error.response?.data?.success)
        toast.error(error.response.data?.error?.msg);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Box sx={{ flex: "1" }}>
          <Typography>Name</Typography>
          <Input
            type="text"
            name="name"
            value={formValues.name}
            onChange={handleInputChange}
            placeholder="Muhammad"
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <Typography>Country</Typography>
          <Input
            type="text"
            name="country"
            value={formValues.country}
            onChange={handleInputChange}
            placeholder="Uzbekistan"
          />
        </Box>
        <Box sx={{ flex: "1" }}>
          <Typography>Number</Typography>
          <Input
            type="tel"
            name="phone"
            value={formValues.phone}
            onChange={handleInputChange}
            placeholder="053-813-1027"
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", gap: "10px" }}>
        <Box>
          <Typography>Gender</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              paddingTop: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>M</Typography>
              <Radio
                checked={formValues.gender === "male"}
                onChange={handleInputChange}
                value="male"
                name="gender"
              />
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography>F</Typography>
              <Radio
                checked={formValues.gender === "female"}
                onChange={handleInputChange}
                value="female"
                name="gender"
              />
            </Box>
          </Box>
        </Box>
        <Box>
          <Typography>Birthday</Typography>
          <Input
            type="date"
            name="birthday"
            value={formValues.birthday}
            onChange={handleInputChange}
            slotProps={{
              input: {
                min: "1950-01-02",
              },
            }}
          />
        </Box>
        <Box>
          <Typography>Visa</Typography>
          <Select
            placeholder="Select a VISA"
            name="visa"
            value={formValues.visa || ""}
            onChange={handleSelectChange}
            indicator={<KeyboardArrowDown />}
            sx={{
              [`& .${selectClasses.indicator}`]: {
                transition: "0.2s",
                [`&.${selectClasses.expanded}`]: {
                  transform: "rotate(-180deg)",
                },
              },
            }}
          >
            <Option value="E-7 (특정활동)">E-7 (특정활동)</Option>
            <Option value="E-8 (계절근로)">E-8 (계절근로)</Option>
            <Option value="E-9 (비전문취업)">E-9 (비전문취업)</Option>
            <Option value="D-2 (유학)">D-2 (유학)</Option>
            <Option value="D-4 (일반연수)">D-4 (일반연수)</Option>
            <Option value="D-8 (기업투자)">D-8 (기업투자)</Option>
            <Option value="D-9 (무역경영)">D-9 (무역경영)</Option>
            <Option value="D-10 (구직)">D-10 (구직)</Option>
            <Option value="F-1 (방문동거)">F-1 (방문동거)</Option>
            <Option value="F-2 (거주)">F-2 (거주)</Option>
            <Option value="F-3 (동반)">F-3 (동반)</Option>
            <Option value="F-4 (재외동포)">F-4 (재외동포)</Option>
            <Option value="F-5 (영주)">F-5 (영주)</Option>
            <Option value="F-6 (결혼이민)">F-6 (결혼이민)</Option>
            <Option value="H-1 (관광취업)">H-1 (관광취업)</Option>
            <Option value="H-2 (방문취업)">H-2 (방문취업)</Option>
            <Option value="C-3 (단기방문)">C-3 (단기방문)</Option>
            <Option value="G-1 (기타)">G-1 (기타)</Option>
            <Option value="E-1 (교수)">E-1 (교수)</Option>
            <Option value="E-3 (연구)">E-3 (연구)</Option>
            <Option value="A-1 (외교)">A-1 (외교)</Option>
            <Option value="D-5 (취재)">D-5 (취재)</Option>
            <Option value="A-2 (공무)">A-2 (공무)</Option>
            <Option value="D-6 (종교)">D-6 (종교)</Option>
            <Option value="D-7 (협정)">D-7 (협정)</Option>
            <Option value="B-1 (사증면제)">B-1 (사증면제)</Option>
            <Option value="E-10 (선원취업)">E-10 (선원취업)</Option>
            <Option value="B-2 (관광통과)">B-2 (관광통과)</Option>
            <Option value="C-1 (일시취재)">C-1 (일시취재)</Option>
            <Option value="C-4 (단기취업)">C-4 (단기취업)</Option>
            <Option value="E-2 (회화지도)">E-2 (회화지도)</Option>
            <Option value="D-1 (문화예술)">D-1 (문화예술)</Option>
            <Option value="E-4 (기술지도)">E-4 (기술지도)</Option>
            <Option value="D-3 (기술연수)">D-3 (기술연수)</Option>
            <Option value="E-5 (전문직업)">E-5 (전문직업)</Option>
            <Option value="E-6 (예술흥행)">E-6 (예술흥행)</Option>
            <Option value="Other">Other</Option>
          </Select>
        </Box>
        <Box>
          <Typography>Address</Typography>
          <Input
            type="text"
            name="address"
            placeholder="서울(Seoul)"
            value={formValues.address}
            onChange={handleInputChange}
          />
        </Box>
      </Box>
      <Box>
        <RadioGroup
          aria-label="Your plan"
          name="people"
          value={selectedOption}
          onChange={handleOptionChange}
          sx={{ marginTop: 2 }}
        >
          <List
            sx={{
              minWidth: 240,
              "--List-gap": "0.5rem",
              "--ListItem-paddingY": "1rem",
              "--ListItem-radius": "8px",
              "--ListItemDecorator-size": "32px",
            }}
          >
            {["Student", "Employee", "Other"].map((item) => (
              <ListItem variant="outlined" key={item} sx={{ boxShadow: "sm" }}>
                <ListItemDecorator>
                  {item === "Student" ? <Person /> : <Apartment />}
                </ListItemDecorator>
                <Radio
                  overlay
                  value={item}
                  label={item}
                  sx={{ flexGrow: 1, flexDirection: "row-reverse" }}
                  slotProps={{
                    action: ({ checked }) => ({
                      sx: (theme) => ({
                        ...(checked && {
                          inset: -1,
                          border: "2px solid",
                          borderColor: theme.vars.palette.primary[500],
                        }),
                      }),
                    }),
                  }}
                />
              </ListItem>
            ))}
          </List>
        </RadioGroup>

        {selectedOption === "Student" && (
          <FormControl required sx={{ width: "100%" }}>
            <FormLabel>
              <Typography>Student</Typography>
            </FormLabel>
            <Input
              type="text"
              name="place_of_study"
              placeholder="Enter your university"
              value={formValues.place_of_study}
              onChange={handleInputChange}
              sx={{ width: "100%" }}
            />
          </FormControl>
        )}
        {selectedOption === "Employee" && (
          <FormControl required sx={{ width: "100%" }}>
            <FormLabel>
              <Typography>Employee</Typography>
            </FormLabel>
            <Input
              type="text"
              name="workplace"
              placeholder="Enter your company"
              value={formValues.workplace}
              onChange={handleInputChange}
            />
          </FormControl>
        )}

        <ReasonComponent
          getReasonData={getReasonData}
          active={active}
          setActive={setActive}
        />

        <Stack gap={4} sx={{ mt: 2 }}>
          <Button onClick={handleSubmit} fullWidth>
            Add
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default NewUser;
